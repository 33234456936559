/***
 *
 *   SIGN UP (user)
 *   Signup form for child users
 *
 **********/

import React, { useContext, useMemo } from 'react';
import { Animate, Row, AuthContext, Card, Form, Link, useAPI } from 'components/lib';

export function SignupUser(props) {
  const context = useContext(AuthContext);
  // const url = window.location.href;
  const urlParams = new URLSearchParams(window.location.search);
  const email = `${urlParams.get('email')}`.replace(/\s+/g, '+');
  const id = urlParams.get('id');
  const name = urlParams.get('name');
  const last_name = urlParams.get('lastname');
  const permissions = urlParams.get('permissions');
  const { data: categories, loading: categoriesLoading } = useAPI('/api/user/categories');
  const { data: metroArea, loading: metroAreaLoading } = useAPI('/api/user/area');
  const categoriesList = useMemo(() => {
    let arr = [];
    if (categories && categories.length > 0 && !categoriesLoading) {
      categories.forEach(({ disabled, label, id }) => {
        if (!disabled) {
          arr.push({ value: id, label });
        }
      });
      return arr;
    } else return [];
  }, [categories, categoriesLoading]);

  const metroAreaList = useMemo(() => {
    let arr = [];
    if (metroArea && metroArea.length > 0 && !metroAreaLoading) {
      metroArea.forEach(({ disabled, label, id }) => {
        if (!disabled) {
          arr.push({ value: id, label });
        }
      });
      return arr;
    } else return [];
  }, [metroArea, metroAreaLoading]);

  return (
    <Animate type="pop">
      <Row title="Create Your Account" color='black'>
        <Card loading={categoriesLoading || metroAreaLoading} restrictWidth center>
          {/* <SocialSignin network={['facebook', 'twitter']} showOr invite={ id } /> */}
          <Form
            inputs={{
              name: {
                label: 'First Name',
                value: name,
                type: 'text',
                required: true,
                errorMessage: 'Please enter your first name',
              },
              last_name: {
                label: 'Last Name',
                value: last_name,
                type: 'text',
                required: true,
                errorMessage: 'Please enter your last name',
              },
              email: {
                label: 'Email',
                value: email,
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
              invite_id: {
                type: 'hidden',
                value: id,
              },
              ...permissions !== "Administrator" && { 
                categories_ids: {
                  label: 'Categories',
                  type: 'multiselect',
                  options: categoriesList,
                  required: true,
                }
              },
              ...permissions !== "Administrator" && {
                metro_area: {
                  label: 'Metro Area',
                  type: 'select',
                  options: metroAreaList,
                  required: true,
                }
              },
              terms: {
                class: 'terms',
                label: 'Terms & Conditions',
                type: 'checkbox',
                options: ['Please confirm <a style="color: rgb(59 130 246 / var(--tw-text-opacity))" target="_blank" href="/terms">Terms & Conditions</a>'],
                required: true,
                default: false,
                errorMessage: 'Checkbox is required',
              },
            }}
            url="/api/user"
            method="POST"
            redirect="/signin"
            buttonText="Create Account"
            buttonColor='black'
            callback={(res) => context.updateEmailVerificationToken(res.data.emailVerificationToken)}
          />
          <div className="mt-4">
            Already registered? <Link url="/signin" text="Sign In" />
          </div>
        </Card>
      </Row>
    </Animate>
  );
}
